import React, { useEffect, useState } from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Paper from "@material-ui/core/Paper";
import { withTranslation, useTranslation } from "react-i18next";
import "./Table.css";
import tag from "../../assets/PACK2RECYCLE_NEW.png";
import logo from "../../assets/logos/LB_Group_RGB.png";

import { httpInstance } from "../../Common/axios";
import { Redirect, Link } from "react-router-dom";
import { useAlert } from "react-alert";
const theme = createMuiTheme({
  overrides: {
    MuiTypography: {
      h6: {
        fontWeight: "bold !important",
        color: "#1D283E",
      },
    },
  },
});

function Customer(props) {
  const alert = useAlert();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [active, setActive] = useState("");
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({
    reports: 0,
    customers: 0,
  });
  const [navigate, setNavigate] = useState(false);
  useEffect(() => {
    function getCustomers() {
      httpInstance.get("/user/customers").then(
        (res) => {
          let data = res.data;
          setLoading(false);
          setData(data.customers);
          setStats({ reports: data.reports, customers: data.customers.length });
        },
        (error) => {
          setLoading(false);
          console.log(error);
        }
      );
    }

    if (data.length === 0 && loading) {
      getCustomers();
    }
  }, [data, loading]);

  const handleClickOpen = (data) => {
    setOpen(true);
    setActive(data);
  };

  const handleClose = () => {
    httpInstance.post("/user/update/status", { id: active._id }).then(
      (res) => {
        setOpen(false);
        alert.show(t(`${res.data.message}`), {
          type: "success",
        });

        httpInstance.get("/user/customers").then(
          (res) => {
            let data = res.data;
            setData(data.customers);
          },
          (error) => {
            console.log(error);
          }
        );
      },
      (error) => {
        alert.show(t(`${error.response.data.message}`), {
          type: "error",
        });
        console.log(error);
      }
    );
  };
  function logoutHandler() {
    localStorage.clear();
    setNavigate(true);
  }
  if (navigate) {
    return <Redirect to={"/login"} />;
  }

  return (
    <div className="background-image" style={{ padding: "30px" }}>
      <Paper>
        <div className="custom-table-styles">
          <ThemeProvider theme={theme}>
            <div className="table-header">
              <img
                src={tag}
                alt="PACK2RECYCLE_NEW.png"
                style={{ width: "150px" }}
              />
              <span className="header_text">{t("Customer")}</span>
              <img
                style={{ width: "150px" }}
                src={logo}
                alt="logo"
              />
            </div>
            <MaterialTable
              localization={{
                pagination: {
                  labelRowsSelect: `${t("rows")}`,
                  labelDisplayedRows: `{from}-{to} ${t("of")} {count}`,
                },
                toolbar: {
                  nRowsSelected: `{0} ${t("row(s)")} ${t("selected")}`,
                  searchTooltip: t("Search"),
                  searchPlaceholder: t("Search"),
                },
                header: {
                  actions: t("Actions"),
                },
                body: {
                  emptyDataSourceMessage: t("No records to display"),
                },
              }}
              title=""
              components={{
                Toolbar: (props) => (
                  <div style={{ textAlign: "center" }}>
                    <div
                      style={{
                        display: "flex",
                        maxWidth: "15%",
                        margin: "0 auto",
                        justifyContent: "space-between",
                        fontWeight: "400",
                      }}
                    >
                      <div>
                        <span style={{ fontWeight: "bold" }}>
                          {stats.customers}
                        </span>
                        <br />
                        <span style={{ fontWeight: "bold" }}>
                          {t("Customers")}
                        </span>
                      </div>
                      <div>
                        <span style={{ fontWeight: "bold" }}>
                          {stats.reports}
                        </span>
                        <br />
                        <span style={{ fontWeight: "bold" }}>
                          {t("Reports")}
                        </span>
                      </div>
                    </div>
                    <MTableToolbar {...props} />
                  </div>
                ),
              }}
              columns={[
                {
                  title: `${t("Customer name")}`,
                  field: "companyName",
                  render: (rowData) => (
                    <Link to={`/customer/${rowData._id}`}>
                      {rowData.companyName}
                    </Link>
                  ),
                },
                { title: `${t("Address")}`, field: "address" },
                { title: `${t("Reports")}`, field: "reports" },
                { title: `${t("Contact")}`, field: "contact_person_name" },
                { title: `${t("E-Mail")}`, field: "contact_person_email" },
                { title: `${t("_Phone")}`, field: "contact_person_phone" },
                {
                  title: `${t("Customer number")}`,
                  field: "customerNumber",
                },
                {
                  title: `${t("Status")}`,
                  field: "status",
                  render: (rowData) => (
                    <span
                      style={{
                        backgroundColor: !rowData.status ? "red" : "green",
                        color: "white",
                        width: "45px"
                      }}
                      className="rating"
                    >
                      {rowData.status === true ? t("Active") : t("Inactive")}
                    </span>
                  ),
                },
                {
                  title: `${t(" ")}`,
                  field: "button",
                  render: (rowData) => (
                    <img
                      alt="dots"
                      className="pointer"
                      onClick={() => handleClickOpen(rowData)}
                      src={"./assets/black.svg"}
                    />
                  ),
                },
              ]}
              data={data}
              options={{
                pageSize: 10,
                pageSizeOptions: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
              }}
            />
            <div className="logout">
              <button
                className="button"
                style={{ marginBottom: "10px" }}
                onClick={logoutHandler}
              >
                {t("Logout")}
              </button>
            </div>
          </ThemeProvider>
        </div>
      </Paper>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={() => { }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {t("Are You Sure ?")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("activate_deactivate_user_description")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
              setActive("");
            }}
            color="primary"
            style={{ color: "red", width: "auto" }}
          >
            {t("Cancel")}
          </Button>
          {active !== "" && !active.status && (
            <Button
              onClick={handleClose}
              color="primary"
              style={{ width: "auto" }}
            >
              {t("Activate")}
            </Button>
          )}
          {active !== "" && active.status && (
            <Button
              onClick={handleClose}
              color="primary"
              style={{ width: "auto" }}
            >
              {t("Deactivate")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default withTranslation()(Customer);
