import React from "react";
import { withTranslation,useTranslation } from 'react-i18next';
function Input(props) {
  const {t} = useTranslation();
  return (
    <>
    <input
      type={props.type}
      className={props.className}
      name={props.name}
      placeholder={t(`${props.placeholder.replace('*', '')}`)}
      value={props.value}
      onChange={props.onChange}
    />
    </>
  );
}
export default withTranslation()(Input)