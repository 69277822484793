import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import configureStore from "./Store/Store";
import { I18nextProvider } from "react-i18next";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "./Common/AlertTemplate";
import i18next from "i18next";
import translationDE from "./translations/de-DE.json";
import translationUS from "./translations/en-US.json";

// const language = navigator.language || navigator.userLanguage;

i18next.init({
  interpolation: { escapeValue: false },
  lng: "de",
  resources: {
    en: {
      translation: translationUS,
    },
    de: {
      translation: translationDE,
    },
  },
});

// optional configuration
const options = {
  position: positions.TOP_RIGHT,
  timeout: 5000,
  offset: "30px",
  transition: transitions.SCALE
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={configureStore()}>
      <I18nextProvider i18n={i18next}>
        <AlertProvider template={AlertTemplate} {...options}>
          <App />
        </AlertProvider>
      </I18nextProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
