import React from 'react';

import '../Login/Login';
import './Welcome.css';
import logo from '../../assets/logos/LB_Group_RGB.png';
import tag from "../../assets/PACK2RECYCLE_NEW.png";

export default function Welcome(props) {
    return (
        <div className="background-image" style={{ height: window.innerWidth >= 768 && window.innerHeight }}>
            <div className="Center-Container">
                <div className="welcome-wrapper is-Fixed">
                    <div className="welcome-white-bg border-primary">
                        <div className="welcome-image-container">
                            <img src={tag} style={{ height: "30px", width: '190px' }} className="minus-10-left" alt="PACK2RECYCLE" />
                            <img src={logo} style={{ height: "40px", width: '120px' }} alt="landbell-white" />
                        </div>
                        <div>
                            <h4 className="font-size-21 font-weight-bold">Herzlich willkommen beim Landbell PACK2RECYCLE-Tool!</h4>
                            <p className="font-size-16"> Das Landbell PACK2RECYCLE-Tool bietet Ihnen die Möglichkeit, eine Ersteinstufung hinsichtlich der Recyclingfreundlichkeit Ihrer Verpackungslösungen zu erhalten. Bitte beachten Sie, dass diese Einstufung nur als Richtwert zu verstehen ist und keine individuelle Bewertung durch einen Sachverständigen auf Basis des Mindeststandards der ZSVR (Stiftung Zentrale Stelle Verpackungsregister) ersetzt.</p>

                            <p className="font-size-16 margin-top-1-33em"> Für eine weitergehende Bewertung nach Mindeststandard oder eine Zertifizierung mit dem PACK2RECYCLE-Siegel kontaktieren Sie uns bitte unter: <a href="mailto:pack2recycle@landbell.de" >pack2recycle@landbell.de</a></p>
                            <h4 className="primary-color font-size-16 font-weight-bold">Und so einfach geht’s:</h4>
                            <ol><li className=" font-size-16">In der Eingabemaske können Sie jede Verpackung einzeln eingeben und für jede Verpackung selbst einen Namen vergeben.</li>
                                <li className=" font-size-16">Anschließend müssen für jede Verpackung entsprechende notwendige Angaben gemacht werden wie beispielsweise Größe, Materialart, Farbe etc.</li>
                                <li className=" font-size-16">Wenn Sie auf „Zur Auswertung“ klicken, wird ein automatisierter Bericht erstellt, der die Recyclingfreundlichkeit Ihrer Verpackung beinhaltet.</li>
                                <li className=" font-size-16">Auf Ihrer Übersichtsseite haben Sie jederzeit Zugriff auf alle von Ihnen eingegebenen Verpackungen inkl. des Ergebnisses der Recyclingfreundlichkeit.</li>
                            </ol>
                        </div>
                        <div>
                            <button  onClick={()=>props.history.push("/evaluation/form")} className="welcome-button">Zur Eingabe </button>
                            <button onClick={()=>props.history.push("/dashboard")} className="welcome-button">Zur Übersicht</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
