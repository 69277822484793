import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Login from "../Components/Login/Login";
import Register from "../Components/Register/Register";
import Table from "../Components/Table/Table";
import PackageSteps from "../Components/PackageSteps";
import Customer from "../Components/Table/Customer";
import Welcome from "../Components/Welcome/Welcome";
import ForgotPassword from "../Components/ForgotPassword";
import ResetPassword from "../Components/ResetPassword";

const CustomerRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        let token = localStorage.getItem("token");

        if (token) {
          let user = JSON.parse(localStorage.getItem("userInfo"));

          if (user.role === "user") {
            return <Component {...props} />;
          } else if (user.role === "admin") {
            return <Redirect to={"/customers"} />;
          }
        } else {
          return <Redirect to={"/login"} />;
        }
      }}
    />
  );
};

const GuestRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        let token = localStorage.getItem("token");
        if (token) {
          return <Redirect to={"/dashboard"} />;
        } else {
          return <Component {...props} />;
        }
      }}
    />
  );
};

const AdminRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        let token = localStorage.getItem("token");
        if (token) {
          let user = JSON.parse(localStorage.getItem("userInfo"));
          if (user.role === "admin") {
            return <Component {...props} />;
          } else {
            return <Redirect to={"/dashboard"} />;
          }
        } else {
          return <Redirect to={"/login"} />;
        }
      }}
    />
  );
};

const AuthRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        let token = localStorage.getItem("token");
        if (token) {
          return <Component {...props} />;
        } else {
          return <Redirect to={"/login"} />;
        }
      }}
    />
  );
};

export default function Routes(props) {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/"
          render={(props) => <Redirect to={"/dashboard"} />}
        />
        <GuestRoute path="/login" component={Login} />
        <GuestRoute path="/register" component={Register} />
        <GuestRoute path="/forgot-password" component={ForgotPassword} />
        <GuestRoute path="/reset-password/:token" component={ResetPassword} />
        <AdminRoute path="/customers" component={Customer} />
        <AdminRoute path="/customer/:id" component={Table} />
        <CustomerRoute path="/dashboard" component={Table} />
        <CustomerRoute
          path="/evaluation/form/:userId?"
          component={PackageSteps}
        />
        <AuthRoute path="/evaluation/:id/:userId?" component={PackageSteps} />
        <Route path="/welcome" component={Welcome} />
      </Switch>
    </Router>
  );
}
