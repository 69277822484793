import React from "react";

import "./App.css";
import Routes from "./Routes/routes";

function App() {
  return (
    <>
      {/* <div className="background-image"> */}
        <Routes />
      {/* </div> */}
    </>
  );
}

export default App;
