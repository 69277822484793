import React from "react";
import "./styles.css";
import logo from "../../assets/logos/LB_Group_RGB_white.png";
import tag from "../../assets/PACK2RECYCLE_NEW.png";
import { useTranslation } from "react-i18next";

function AuthLayout({ title, children }) {
  const { t } = useTranslation();

  return (
    <div
      className="background-image"
      style={{ height: window.innerWidth >= 768 && window.innerHeight }}
    >
      <div className="center-container">
        <div className="absolute-center is-Fixed">
          <img
            src={logo}
            style={{ marginBottom: "40px", width: "280px" }}
            alt={"background"}
          />
          <div className="white-bg">
            <img
              src={tag}
              alt="PACK2RECYCLE_NEW.png"
              style={{ width: "250px", marginTop: "15px" }}
            />
            <h2 className="form_heading">{t(title)}</h2>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthLayout;
