import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAlert } from "react-alert";
import Input from "../Input/Input";
import { loginDetails } from "./loginDetails";
import { withTranslation, useTranslation } from "react-i18next";
import { httpInstance } from "../../Common/axios";
import AuthLayout from "../Layout/auth-layout";

const defaultForm = {
  email: "",
  password: "",
};

function Login(props) {
  const { t } = useTranslation();
  const alert = useAlert();

  const [loginDetail, setLoginDetail] = useState([]);
  const [form, setForm] = useState(defaultForm);

  useEffect(() => {
    setLoginDetail(loginDetails);
  }, [loginDetail]);

  const formSubmitHandler = () => {
    httpInstance.post("/user/login", form).then(
      (res) => {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("userInfo", JSON.stringify(res.data.user));
        httpInstance.defaults.headers.common["Authorization"] = res.data.token;

        if (res.data.user.role === "admin") {
          props.history.push("/customers");
          return;
        }
        alert.show(t(`${res.data.message}`), { type: "success" });
        props.history.push("/welcome");
      },
      (error) => {
        alert.show(t(`${error.response.data.message}`), { type: "error" });
      }
    );
  };

  function handleChange(event) {
    let values = { ...form };
    values[event.target.name] = event.target.value;

    setForm({
      ...form,
      ...values,
    });
  }

  function canSubmit() {
    return (
      form.email &&
      form.email.length > 0 &&
      form.email.indexOf("@") > -1 &&
      form.password &&
      form.password.length > 0
    );
  }

  return (
    <AuthLayout
      title={"Log In"}
      children={
        <div className="form-container">
          {loginDetail.length > 0 &&
            loginDetail.map((data, index) => (
              <Input
                key={index}
                name={data.name}
                className={`${
                  data.isValid
                    ? `${data.className} input-border`
                    : `error_border ${data.className} `
                }`}
                type={data.type}
                placeholder={data.placeholder}
                value={form[data.name]}
                onChange={handleChange}
              />
            ))}

          <button
            className="form-input-submit button"
            onClick={formSubmitHandler}
            disabled={!canSubmit()}
          >
            {t("Log In")}
          </button>
          <div className="register-text">
            <Link to="/forgot-password">{t("Forgot password?")}</Link>
          </div><br/>
          <span className="register-text margin-top-none">
            {t("No account Yet?")} <Link to="/register">{t("Sign Up")}.</Link>
          </span>
        </div>
      }
    />
  );
}
export default withTranslation()(Login);
