export const requiredFields = [
  "Abdeckung der Hauptfläche mit dem Label (in %)",
  "Anteil von Sekundärrohstoffen an der Verpackung (in %)",
  "Art des Klebers beim Label",
  "Einsatzbereich der Verpackung",
  "Farbe der Verpackung",
  "Größe der Verpackung",
  "Hauptmaterial der Verpackung",
  "Material des Labels",
  "Verpackungsmaterialkombination",
  "Verpackungstyp",
];

export const weight = {
  "Abdeckung der Hauptfläche mit dem Label (in %)": 0.5,
  "Anteil von Sekundärrohstoffen an der Verpackung (in %)": 0.1,
  "Art des Klebers beim Label": 0.5,
  "Einsatzbereich der Verpackung": 0,
  "Farbe der Verpackung": 1,
  "Größe der Verpackung": 2,
  "Hauptmaterial der Verpackung": 2,
  "Material des Labels": 0.3,
  Verpackungsmaterialkombination: 2,
  Verpackungstyp: 1,
};

export function getKeinLabelValue(formValues) {
  const conditions1 = [
    "Gleiches Grundmaterial",
    "PP Flasche + PE Kappe",
    "PP Flasche + PP Kappe",
    "PE Flasche + PP Kappe",
    "PE Flasche + PE Kappe",
    "PE Flexibles ab 20 gramm",
    "PE Kunststofftube + PP Kappe",
    "PP Box/Tiegel + PP Deckel",
  ];

  const conditions2 = ["PP", "PE-HD", "PE-LD"];

  const conditions3 = [
    "PE Flexibles bis 20 gramm",
    "PE/PA Flasche + PP Kappe",
    "PET/PE Flexibles bis 20 gramm",
    "PET/PE Flexibles ab 20 gramm",
    "PET Flexibles bis 20 gramm",
    "PET Flexibles ab 20 gramm",
    "OPP/PE Flexibles bis 20 gramm",
    "OPP/PE Flexibles ab 20 gramm",
    "PET Flasche + PP Kappe",
    "PET Flasche + PE Kappe",
    "Alu-Verbund mit Alu Anteil unter 25%",
    "PE/PA Flexibles bis 20 gramm (PA Anteil >25%)",
    "PE/PA Flexibles ab 20 gramm (PA Anteil <25%)",
    "PPK/PE/PP Verbund (FSK)",
    "PLA/PE",
    "PLA/PET",
    "PLA/PP",
    "Alu-Verbund mit Alu Anteil über 25%",
    "Alu-Tube + PE Kappe",
    "Alu-Tube + PP Kappe",
    "PS + PP Tiegel + PP Deckel",
    "Alu-Sperrschichtlaminat Tube + PP Kappe",
    "Kunststoffbariereverbund Tube +  PP Kappe",
  ];

  const conditions4 = [
    "PP",
    "PE-HD",
    "PE-LD",
    "PET",
    "Papier",
    "PLA",
    "Sonstiger Kunststoff",
  ];

  const materialDesLabels = formValues["Material des Labels"];
  const verpackungsmaterialkombination =
    formValues["Verpackungsmaterialkombination"];
  let value = 100;

  if (
    conditions1.includes(materialDesLabels) &&
    conditions2.includes(verpackungsmaterialkombination)
  ) {
    value = 65;
  } else if (
    conditions3.includes(materialDesLabels) &&
    conditions4.includes(verpackungsmaterialkombination)
  ) {
    value = 0;
  }

  return value;
}
