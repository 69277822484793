import React from "react";
import "./step-two.css";
import img from "../../assets/header_2022_resized.png";
import { requiredFields } from "../../helpers/validations";
import { withTranslation, useTranslation } from "react-i18next";
import BeatLoader from "react-spinners/BeatLoader";


function StepOne({
  options = [],
  handleChange,
  values,
  handleSubmit,
  loading,
}) {
  const { t } = useTranslation();

  function handleSelectChange(event) {
    let newValues = { ...values };
    let value =
      event.target.value && event.target.value !== ""
        ? event.target.value
        : `0_empty`;
    newValues[event.target.name] = value;

    if (
      event.target.name === "Material des Labels" &&
      event.target.value === "100_Kein Label"
    ) {
      newValues["Abdeckung der Hauptfläche mit dem Label (in %)"] = "100_0";
      newValues["Art des Klebers beim Label"] =
        "100_kein Label oder aufgedruckt";
    }

    if (
      event.target.name === "Einsatzbereich der Verpackung" &&
      event.target.value === "0_Lebensmittel Kontakt"
    ) {
      newValues["Anteil von Sekundärrohstoffen an der Verpackung (in %)"] =
        "60_60";
    }

    handleChange(newValues);
  }

  function handleTextChange(event) {
    let newValues = { ...values };
    let value = event.target.value;
    newValues[event.target.name] = value;
    handleChange(newValues);
  }

  function isDisabled() {
    let disabled = false;
    if (Object.keys(values).length === 0) {
      disabled = true;
    } else {
      for (let field of requiredFields) {
        if (values[field] === undefined && isSelectDisabled() === false) {
          disabled = true;
          break;
        }
      }
    }

    return disabled;
  }

  function isSelectDisabled(evaluation = "") {
    const evaluationName =
      "Anteil von Sekundärrohstoffen an der Verpackung (in %)";
    return (
      evaluation === evaluationName &&
      values["Einsatzbereich der Verpackung"] === "0_Lebensmittel Kontakt"
    );
  }

  function isTotalZero() {
    return values["Größe der Verpackung"] === "0_< 5 cm";
  }

  return (
    <div className="testbox">
      <div id="verpackungsinfo" className={"form"}>
        <div>
          <div className="banner">
            {/* <h1>Recyclingfreundlichkeit bewerten</h1> */}
            <img className="banner_image" src={img} alt={"step-one"} />
          </div>
        </div>
        <h2 className="primary-color font-weight-bold">Verpackungsdetails</h2>
        {isTotalZero() && (
          <div className={"alert alert-danger"}>
            Nach Stand der Technik nicht sortierbar
          </div>
        )}
        <div className="item">
          <p>Name der Verpackung</p>
          <input
            id="verpackungsname"
            type="text"
            value={values["verpackungsname"]}
            name="verpackungsname"
            onChange={handleTextChange}
          />
        </div>
        {options.map((option, index) => (
          <div className="item" id="Einsatzbereich" key={index}>
            <p>{t(`${option.evaluation}`)}</p>
            {console.log(values[option.evaluation],"values[option.evaluation")}
            <select
              name={option.evaluation}
              onChange={handleSelectChange}
              value={t(`${values[option.evaluation]}`)}
              disabled={isSelectDisabled(option.evaluation)}
            >
              <option value="" />
              {option.options.map((selectOptions, key) => (
                <option
                  key={key}
                  value={t(`${selectOptions.points}_${selectOptions.name}`)}
                  index={index}
                >
                  {t(selectOptions.name)}
                </option>
              ))}
            </select>
          </div>
        ))}
        <div className="btn-block">
          <button
            className={"ml-12 button width-save"}
            disabled={isDisabled() || loading}
            onClick={handleSubmit}
          >
            {loading ? <BeatLoader color={'#76B82A'} loading={true} size={15}/> : `Speichern und zur Auswertung`}
          </button>
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(StepOne);
