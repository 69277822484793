import React, { useState } from "react";
import Input from "../Input/Input";
import { Link } from "react-router-dom";
import AuthLayout from "../Layout/auth-layout";
import { useTranslation } from "react-i18next";
import "../Login/Login.css";
import { httpInstance } from "../../Common/axios";
import { useAlert } from "react-alert";

function ResetPassword(props) {
  const { t } = useTranslation();
  const alert = useAlert();
  const [form, setForm] = useState({ password: "", confirmPassword: "" });

  function handleChange(event) {
    let values = { ...form };
    values[event.target.name] = event.target.value;

    setForm({ ...form, ...values });
  }

  function formSubmitHandler() {
    const token = props.match.params.token;

    const headers = {
      "password-reset": token,
    };

    httpInstance.post("/user/update/password", form, { headers }).then(
      (res) => {
        alert.show(t(`${res.data.message}`), { type: "success" });
        props.history.push('/login')
      },
      (error) => {
        alert.show(t(`${error.response.data.message}`), { type: "error" });
      }
    );
  }

  function canSubmit() {
    return (
      form.password.length > 0 &&
      form.confirmPassword.length > 0 &&
      form.password === form.confirmPassword
    );
  }

  return (
    <AuthLayout
      title={"Reset Password"}
      children={
        <div className="form-container">
          <Input
            name={"password"}
            className={`form-input input-border`}
            type={"password"}
            placeholder={t("Enter new password")}
            value={form.password}
            onChange={handleChange}
          />

          <Input
            name={"confirmPassword"}
            className={`form-input input-border`}
            type={"password"}
            placeholder={t("Repeat new password")}
            value={form.confirmPassword}
            onChange={handleChange}
          />

          {form.password !== form.confirmPassword &&
            form.confirmPassword.length > 0 && (
              <span className={"error"}>{t("Passwords must be same")}</span>
            )}

          <button
            className="form-input-submit button"
            onClick={formSubmitHandler}
            disabled={!canSubmit()}
          >
            {t("Update Password")}
          </button>
          <span className="register-text">
            <Link to="/login">{t("Login here")}.</Link>
          </span>
        </div>
      }
    />
  );
}

export default ResetPassword;
