import React, { useState } from "react";
import Input from "../Input/Input";
import { Link } from "react-router-dom";
import AuthLayout from "../Layout/auth-layout";
import { useTranslation } from "react-i18next";
import "../Login/Login.css";
import { httpInstance } from "../../Common/axios";
import { useAlert } from "react-alert";

function ForgotPassword(props) {
  const { t } = useTranslation();
  const alert = useAlert();
  const [form, setForm] = useState({ email: "" });

  function handleChange(event) {
    let values = { ...form };
    values[event.target.name] = event.target.value;

    setForm({ ...form, ...values });
  }

  function formSubmitHandler() {
    httpInstance.post('/user/send/reset/email', form).then(res => {
      alert.show(t(`${res.data.message}`), { type: "success" });
      props.history.push('/login')
    }, error => {
      alert.show(t(`${error.response.data.message}`), { type: "error" });
    })
  }

  function canSubmit() {
    return form.email.length > 0 && form.email.indexOf('@') > -1;
  }

  return (
    <AuthLayout
      title={"Forgot Password"}
      children={
        <div className="form-container">
          <Input
            name={"email"}
            className={`form-input input-border`}
            type={"email"}
            placeholder={t("Please enter your email")}
            value={form.email}
            onChange={handleChange}
          />

          <button
            className="form-input-submit button"
            onClick={formSubmitHandler}
            disabled={!canSubmit()}
          >
            {t("Send reset email")}
          </button>
          <span className="register-text">
            <Link to="/login">{t("Login here")}.</Link>
          </span>
        </div>
      }
    />
  );
}

export default ForgotPassword;
