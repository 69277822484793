import React, { useEffect, useState } from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { withTranslation, useTranslation } from "react-i18next";
import { httpInstance } from "../../Common/axios";
import { Redirect, Link } from "react-router-dom";

import "./Table.css";
import tag from "../../assets/PACK2RECYCLE_NEW.png";
import logo from "../../assets/logos/LB_Group_RGB.png";

const theme = createMuiTheme({
  overrides: {
    MuiTypography: {
      h6: {
        fontWeight: "bold !important",
        color: "#1D283E",
      },
    },
  },
});
function Table(props) {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const id = props.match.params.id;
  const [loading, setLoading] = useState(true);
  const [navigate, setNavigate] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    function getCustomers() {
      let user = id
        ? { _id: id }
        : JSON.parse(localStorage.getItem("userInfo"));
      httpInstance
        .post("/evaluations/user", {
          id: user._id,
        })
        .then(
          (res) => {
            setLoading(false);
            let data = res.data;
            setData(data.evaluations);
            setSelectedUser(data.user);
          },
          (error) => {
            setLoading(false);
            console.log(error);
          }
        );
    }

    if (data.length === 0 && loading) {
      getCustomers();
    }
  }, [data, id, loading]);

  function handleButtonClick() {
    const win = window.open(`/evaluation/form/${selectedUser._id}`, "_blank");
    win.focus();
  }
  function calculate(data, type) {
    let myCounter = 0;
    data.forEach((v) =>
      type === "unfriendly" && v.total <= 75
        ? myCounter++
        : type === "limited" && v.total > 75 && v.total <= 82
        ? myCounter++
        : type === "friendly" && v.total > 82 && v.total <= 122
        ? myCounter++
        : v
    );
    return myCounter;
  }
  function logoutHanadler() {
    localStorage.clear();
    setNavigate(true);
  }
  if (navigate) {
    return <Redirect to={"/login"} />;
  }
  return (
    <>
      <div className="background-image" style={{ padding: "30px" }}>
        <Paper>
          <div className="custom-table-styles">
            <ThemeProvider theme={theme}>
              <div className="table-header">
                <img
                  src={tag}
                  alt="PACK2RECYCLE_NEW.png"
                  style={{ width: "150px" }}
                />
                <span className="header_text">{t("Packaging")}</span>
                <img
                  style={{ width: "150px" }}
                  src={logo}
                  alt="logo"
                />
              </div>
              <MaterialTable
                localization={{
                  pagination: {
                    labelRowsSelect: `${t("rows")}`,
                    labelDisplayedRows: `{from}-{to} ${t("of")} {count}`,
                  },
                  toolbar: {
                    nRowsSelected: `{0} ${t("row(s)")} ${t("selected")}`,
                    searchTooltip: t("Search"),
                    searchPlaceholder: t("Search"),
                  },
                  header: {
                    actions: t("Actions"),
                  },
                  body: {
                    emptyDataSourceMessage: t("No records to display"),
                  },
                }}
                title=""
                components={{
                  Toolbar: (props) => (
                    <div style={{ textAlign: "center" }}>
                      <div
                        style={{
                          display: "flex",
                          maxWidth: "30%",
                          margin: "0 auto",
                          justifyContent: "space-between",
                          fontWeight: "400",
                        }}
                      >

                        <div>
                          <span style={{ fontWeight: "bold" }}>
                            {data.length}
                          </span>
                          <br />
                          <span style={{ fontWeight: "bold" }}>
                            {t("Report")}
                          </span>
                        </div>
                        <div
                          style={{
                            backgroundColor: "green",
                            color: "white",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            {calculate(data, "friendly")}
                          </span>

                          <span
                            className="details-tab"
                            style={{ fontWeight: "bold" }}
                          >
                            {t("recycling")}-{t("friendly")}
                          </span>
                        </div>
                        <div
                          style={{
                            backgroundColor: "#FDC000",
                            color: "white",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            {calculate(data, "limited")}
                          </span>
                          <span
                            className="details-tab"
                            style={{ fontWeight: "bold" }}
                          >
                            {t("Limited")}
                            <br />
                            {t("recycling")}-{t("friendly")}
                          </span>
                        </div>
                        <div
                          style={{
                            backgroundColor: "red",
                            color: "white",
                            fontWeight: "bold",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            {calculate(data, "unfriendly")}
                          </span>

                          <span
                            className="details-tab"
                            style={{ fontWeight: "bold" }}
                          >

                            {t("unfriendly")}
                            <br />
                            {t("recycling")}-{t("friendly")}
                          </span>
                        </div>
                      </div>
                      <MTableToolbar {...props} />
                    </div>
                  ),
                }}
                columns={[
                  {
                    title: `${t("Packaging name")}`,
                    field: "name",
                    render: (rowData) => (
                      <Link
                        to={`/evaluation/${rowData._id}/${rowData.user_id}`}
                      >
                        {rowData.name}
                      </Link>
                    ),
                  },
                  {
                    title: `${t("Rating")}`,
                    field: "Rating",
                    render: (rowData) => (
                      <span
                        className="rating"
                        style={{
                          backgroundColor:
                            rowData.total <= 75
                              ? "red"
                              : rowData.total > 75 && rowData.total <= 82
                              ? "#FDC000"
                              : "green",
                          color: "white",
                        }}
                      >
                        {rowData.total <= 75
                          ? `${t("unfriendly_recycling")}`
                          : rowData.total > 75 && rowData.total <= 82
                          ? `${t("limited_recycling_friendly")}`
                          : `${t("recycling_friendly")}`
                        }
                      </span>
                      ),
                  },
                  { title: `${t("Packaging type")}`, field: "packageType" },
                  { title: `${t("Main material")}`, field: "material" },
                  { title: `${t("Size")}`, field: "size" },
                  { title: `${t("Color")}`, field: "color" },
                  { title: `${t("Label material")}`, field: "materialLabel" },
                ]}
                data={data}
                options={{
                  pageSize: 10,
                  pageSizeOptions: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
                }}
              />
              <div className="btn-box">
                <div className="logout">
                  <button className="button" onClick={logoutHanadler}>
                    {t("Logout")}
                  </button>
                </div>
                {id === undefined && (
                  <div className="text-align-right">
                    <button className="button" onClick={handleButtonClick}>
                      {t("New Packaging")}
                    </button>
                  </div>
                )}
              </div>
            </ThemeProvider>
          </div>
        </Paper>
      </div>
      {/* <Welcome /> */}
    </>
  );
}
export default withTranslation()(Table);
