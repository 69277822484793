import axios from "axios";

export const baseURL = "https://vbt.landbell.de"
// export const baseURL = "http://localhost:8080"

export const httpInstance = axios.create({
  baseURL: baseURL,
  headers: {
    common: {
      Authorization: `${localStorage.getItem("token")}`
    }
  }
});

httpInstance.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  // Do something with response error
  if (error.response.status === 401) {
    if (localStorage.getItem('token') && localStorage.getItem('userInfo')) {
      localStorage.clear()
      window.location.href = '/'
    }
  }
  return Promise.reject(error);
});


