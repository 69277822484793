import React, { useState, useEffect } from "react";
import "../../App.css";
import StepOne from "./StepOne";
import ReactDOMServer from "react-dom/server";
import PdfComponent from "./PdfComponent";
import {
  getKeinLabelValue,
  requiredFields,
  weight,
} from "../../helpers/validations";
import { baseURL, httpInstance } from "../../Common/axios";

function PackageSteps(props) {
  const [step, setStep] = useState(0);
  const [options, setOptions] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const id = props.match.params.id;
  const userId = props.match.params.userId;

  useEffect(() => {
    function getUser() {
      httpInstance.get(`/user/info/${userId}`).then(
        (res) => {
          let user = res.data.user;
          setSelectedUser(user);
        },
        (error) => {
          console.log(error);
        }
      );
    }
    if (userId && !selectedUser) {
      getUser();
    }
  }, [userId, selectedUser]);

  useEffect(() => {
    function getOptions() {
      httpInstance.get("/evaluations/fetch").then(
        (res) => {
          setOptions(res.data);
        },
        (error) => {
          console.log(error);
        }
      );
    }

    if (options.length === 0) {
      getOptions();
    }
  }, []);

  useEffect(() => {
    function getEvaluation() {
      httpInstance.get(`/evaluations/${id}`).then(
        (res) => {
          let evaluation = res.data.evaluation.evaluation;
          evaluation._id = res.data.evaluation._id;
          setFormValues(evaluation);
        },
        (error) => {
          console.log(error);
        }
      );
    }
    if (id) {
      getEvaluation();
    }
  }, [id]);

  function handleFormChange(values) {
    setFormValues({
      ...formValues,
      ...values,
    });
  }

  function handleSubmit() {
    setLoading(true);
    let totalAmount = 0;
    if (formValues["Größe der Verpackung"] !== "0_< 5 cm") {
      for (let key of Object.keys(formValues)) {
        if (requiredFields.includes(key)) {
          let val = formValues[key].split("_");
          let value =
            key === "Material des Labels" &&
            formValues[key] === "100_Kein Label"
              ? getKeinLabelValue(formValues)
              : parseInt(val[0]);

          totalAmount += value * weight[key];
        }
      }
    }

    totalAmount = (totalAmount / 9).toFixed(2);

    setTimeout(() => {
      let user = JSON.parse(localStorage.getItem("userInfo"));

      if (user && user.role === "admin") {
        user = selectedUser;
      }

      let html = ReactDOMServer.renderToStaticMarkup(
        <PdfComponent
          total={totalAmount}
          name={formValues["verpackungsname"]}
          user={user}
          {...formValues}
        />
      );

      httpInstance
        .post("/evaluations/save", { formValues, html, total: totalAmount })
        .then((res) => {
          setLoading(false);
          window.open(`${baseURL}/evaluations/view/${res.data.name}`, "_blank");
          let user = JSON.parse(localStorage.getItem("userInfo"));
          if (user.role === "admin") {
            props.history.goBack();
          } else {
            props.history.push("/");
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }, 200);
  }

  return (
    <div className="background-image">
      <div className="text-align-left Absolute-Center ">
        {step === 0 && (
          <StepOne
            options={options}
            values={formValues}
            setStep={setStep}
            handleChange={handleFormChange}
            handleSubmit={handleSubmit}
            loading={loading}
          />
        )}
      </div>
    </div>
  );
}

export default PackageSteps;
