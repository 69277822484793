import React, { useEffect, useState } from "react";
import { withTranslation, useTranslation } from "react-i18next";
import { useAlert } from "react-alert";
import { Link } from "react-router-dom";
import Input from "../Input/Input";
import { RegisterDetails } from "./RegisterDetails";
import "../Layout/styles.css";
import tag from "../../assets/PACK2RECYCLE_NEW.png";
import { httpInstance } from "../../Common/axios";

let defaultForm = {
  companyName: "",
  customerNumber: "",
  houseNumber: "",
  zipcode: "",
  city: "",
  contactPersonName: "",
  contactPersonEmail: "",
  contactPersonPhone: "",
  email: "",
  password: "",
  password_confirm: "",
  terms: false,
};

function Register(props) {
  const { t } = useTranslation();
  const [form, setForm] = useState(defaultForm);
  const [registerDetail, setRegisterDetail] = useState([]);
  const [notification, setNotification] = useState(false);
  const alert = useAlert();

  useEffect(() => {
    setRegisterDetail(RegisterDetails);
  }, [registerDetail]);

  const handleSubmit = () => {
    httpInstance.post("/user/register", form).then(
      (res) => {
        setNotification(true);
      },
      (error) => {
        alert.show(t(`${error.response.data.message}`), { type: "error" });
      }
    );
  };

  function handleChange(event) {
    let values = { ...form };
    values[event.target.name] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    setForm({
      ...form,
      ...values,
    });
  }

  function canSubmit() {
    return (
      form.email.length > 0 &&
      form.password.length > 0 &&
      form.password_confirm.length > 0 &&
      form.companyName.length > 0 &&
      form.customerNumber.length > 0 &&
      form.houseNumber.length > 0 &&
      form.zipcode.length > 0 &&
      form.city.length > 0 &&
      form.contactPersonName.length > 0 &&
      form.contactPersonEmail.length > 0 &&
      form.contactPersonPhone.length > 0 &&
      form.terms
    );
  }

  return (
    <div className="background-image">
      <div className="center-container">
        <div className="absolute-center Absolute-Center-register is-Fixed">
          <div className="white-bg">
            <img
              src={tag}
              alt="PACK2RECYCLE_NEW.png"
              style={{ width: "250px", marginTop: "15px" }}
            />
            {notification && (
              <div class="alert alert-success" role="alert">
                Ihr Konto wurde erfolgreich erstellt! <br />
                Landbell wird Ihr Konto schnellstmöglich freigeben und Sie benachrichtigen.
                <br />
                Sobald Ihr Konto aktiviert wurde, klicken Sie <Link to="/login"> hier</Link>, um sich anzumelden
              </div>
            )}
            {!notification && (
              <React.Fragment>
                <h2 className="form_heading">{t("_Register")}</h2>
                <div className="form-container">
                  {RegisterDetails.length > 0 &&
                    RegisterDetails.map((data, index) => (
                      <React.Fragment key={index}>
                        <Input
                          className={`${`errors${data.name}`
                            ? `input-border ${data.className} `
                            : `error_border ${data.className} `
                            }`}
                          type={data.type}
                          name={data.name}
                          placeholder={`${data.placeholder}*`}
                          value={data[data.name]}
                          onChange={handleChange}
                        />
                      </React.Fragment>
                    ))}

                  {form.password !== form.password_confirm && (
                    <span style={{ color: "red" }}>{t('Passwords must be same')}</span>
                  )}

                  <div>
                    <input
                      type="checkbox"
                      name="terms"
                      id={"terms"}
                      required
                      onChange={handleChange}
                    />
                    <label htmlFor="terms" className="check text-align-left">
                      <span className="margin-left display-inline-block">
                        {t("I agree to the")}{" "}
                        <a
                          target="_blank"
                          href="https://landbell-group.com/wp-content/uploads/2020/07/Customers-Privacy-Notice-DE020702020.pdf"
                          rel="noreferrer"
                        >
                          {t("Privacy Policy")}
                        </a>
                        <span>&nbsp;{t('zu')}</span>
                      </span>
                    </label>
                  </div>
                  <button
                    className="form-input-submit button"
                    onClick={handleSubmit}
                    disabled={!canSubmit()}
                  >
                    {t("Register")}
                  </button>

                  <span className="register-text">
                    {t("Already Signed up?")}{" "}
                    <Link to="/login">{t("Sign in")}.</Link>
                  </span>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default withTranslation()(Register);
