import React from "react";
import "./pdf-styles.css";
import { baseURL } from "../../Common/axios";
import LogoImg from '../../assets/logos/LB_Group_RGB.png';
import ArrowImg from '../../assets/pdf-images/arrow.jpg';
import RoundArrow2Img from '../../assets/pdf-images/round-arrow2.png';
import RoundArrow1Img from '../../assets/pdf-images/round-arrow1.png';
import RoundArrowImg from '../../assets/pdf-images/round-arrow.png';
import Bin1Img from '../../assets/pdf-images/bin1.jpg';
import Bin2Img from '../../assets/pdf-images/bin2.jpg';
import Bin3Img from '../../assets/pdf-images/bin3.jpg';
console.log(Bin3Img, 'e');

const fields = {
  "Größe der Verpackung":
    "Vergrösserung der Verpackung auf >5cm verbessert die Sortierfähigkeit",
  "Hauptmaterial der Verpackung": "Einsatz eines anderen Primärstoffes",
  "Farbe der Verpackung": "Einsatz von Infrarot detektierbaren Batches",
  Verpackungsmaterialkombination:
    "Gleiche Materialtypen verbessern die Sortierfähigkeit",
  "Material des Labels": "Label und Verpackung aus einem Grundmaterial",
  "Abdeckung der Hauptfläche mit dem Label (in %)":
    "Label als Aufdruck auf der Verpackung prüfen",
  "Art des Klebers beim Label":
    "wasserlöslichen Kleber verwenden oder punktuell kleben",
  "Anteil von Sekundärrohstoffen an der Verpackung (in %)":
    "Einsatz von Sekundärrohstoff prüfen",
};

export default function PdfComponent(props) {
  const { name, total, user } = props;

  function matchValue(key) {
    let val = props[key].split("_");
    // let value = key === 'Material des Labels' && props[key] === '100_Kein Label' ? getKeinLabelValue(props) : parseInt(val[0])
    let value = parseInt(val[0]);
    return val[1] === "Anteil von Sekundärrohstoffen an der Verpackung (in %)"
      ? value === 0
      : value < 50;
  }

  return (
    <>
      <div id="page-top">
        <div  className="mk-header my-5 py-5">
          <div className="container">
            <div className="text-right">
              <img
                className="img-fluid"
                style={{ width: "130px", marginTop:'40px' }}
                src={LogoImg}
                alt=""
              />
            </div>
            <div className="mt-4">
              <div style={{ marginTop: '20px', height: '36px' }}>
                &nbsp;
              </div>
            </div>
          </div>
        </div>

        <div className="mg-10rem mx-45">
          <div className="container">
            <div className="text-color">
              <h1 className="font-s ">
                ERSTEINSTUFUNG <br /> IHRER VERKAUFSVERPACKUNG
              </h1>
            </div>
          </div>
        </div>

        <div className={"mt-5 mg-3rem mx-45 flex justify-space-between"}>
          <div>
            <div className={"container"}>
              <div className="text-color">
                <p className="font-size-18px color-404040">
                  {user.companyName}
                </p>
                <p className="font-size-18px color-404040">
                  {user.houseNumber}
                </p>
                <p className="font-size-18px color-404040">
                  {user.zipcode} {user.city}
                </p>
              </div>
            </div>
          </div>

          <div>
            <div className="container">
              <div className="text-color">
                <p className="font-size-18px color-404040">
                  Name der Verpackung:
                </p>
                <p className="font-size-18px color-404040">{name}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-5">
          <div className="container">
            <div className="text-color text-center">
              <h2 className="color-404040 font-size-15">ERSTEINSTUFUNG*:</h2>
              <h2 className="font-weight-900 color-404040 text-transform-uppercase font-size-15">
                Ihre Verpackung ist{" "}
                {`${
                  total <= 75
                    ? "nicht recyclingfreundlich"
                    : total > 75 && total <= 82
                    ? "bedingt reyclingfreundlich"
                    : "recyclingfreundlich"
                }`}
              </h2>
            </div>
          </div>
        </div>

        <div className="mt-5 mg-3rem">
          <div className="container">
            <div className="bin-box">
              <div className="text-center w-18">
                {total <= 75 && (
                  <img
                    className="w-70"
                    src={RoundArrow2Img}
                    alt=""
                  />
                )}
              </div>

              <div className="text-center w-26 bin-box-wrapper">
                {total > 75 && total <= 82 && (
                  <img
                    className="w-50"
                    src={RoundArrow1Img}
                    alt=""
                  />
                )}
              </div>

              <div className="text-center w-30 bin-box-wrapper">
                {total > 82 && total <= 122 && (
                  // console.log(total)
                  <img
                    className="w-43"
                    src={RoundArrowImg}
                    alt=""
                  />
                )}
              </div>
            </div>
            <div className="bin-box align-items-baseline">
              <div className="text-center w-18">
                <img
                  className="img-fluid"
                  src={Bin1Img}
                  alt=""
                  style={{ marginBottom: "6px" }}
                />
              </div>
              <div className="text-center w-26">
                <img
                  className="img-fluid"
                  src={Bin2Img}
                  alt=""
                  style={{ marginBottom: "2px" }}
                />
              </div>
              <div className="text-center w-30">
                <img
                  className="img-fluid"
                  src={Bin3Img}
                  alt=""
                  style={{ marginBottom: "2px" }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-5 mg-3rem">
          <div className="container">
            <div className="text-color text-center">
              <p className="font-bold font-size-18px color-404040">
                Ursachen Bewertungsabzug:
              </p>
              {Object.keys(props).map(
                (key) =>
                  fields[key] &&
                  matchValue(key) && (
                    <p className="font-size-16px color-404040">{fields[key]}</p>
                  )
              )}
            </div>
          </div>
        </div>
        <div className="mt-5 mg-3rem mx-45">
          <div style={{ marginTop: "60px" }} className="container">
            <div s className="text-color ">
              <p className="font-bold font-size-16px color-404040">
                {" "}
                <b>
                  * Diese Ersteinstufung ist nur als Richtwert zu verstehen und
                  ersetzt keine Bewertung Ihrer Verpackung nach Mindeststandard
                  der ZSVR (Zentralen Stelle Verpackungsregister).
                </b>
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="image">
        <img  style={{width:'100%'}} src='http://142.93.99.55/companys/image/1621244674_footer_logo.jpg'  alt="footerimage "/>
      </div> */}
    </>
  );
}
