export const loginDetails = [
  {
    className: "form-input",
    name: "email",
    type: "email",
    placeholder: "User email",
    value: "",
    isValid: true,
  },
  {
    className: "form-input",
    type: "password",
    name: "password",
    placeholder: "Password",
    value: "",
    isValid: true,
  },
];
