export const RegisterDetails = [
  {
    className: "form-input",
    type: "text",
    placeholder: "Company Name",
    value: "",
    isValid: true,
    name: "companyName",
  },
  {
    className: "form-input",
    type: "text",
    placeholder: "Company number",
    value: "",
    isValid: true,
    name: "customerNumber",
  },
  {
    className: "form-input",
    type: "text",
    placeholder: "Street,House number",
    value: "",
    isValid: true,
    name: "houseNumber",
  },
  {
    className: "form-input",
    type: "text",
    placeholder: "Zipcode",
    value: "",
    isValid: true,
    name: "zipcode",
  },
  {
    className: "form-input",
    type: "text",
    placeholder: "City",
    value: "",
    isValid: true,
    name: "city",
  },
  {
    className: "form-input margin-top",
    type: "text",
    placeholder: "Name Contact Person",
    value: "",
    isValid: true,
    name: "contactPersonName",
  },
  {
    className: "form-input",
    type: "email",
    placeholder: "Email Address",
    value: "",
    isValid: true,
    name: "contactPersonEmail",
  },
  {
    className: "form-input",
    type: "phone",
    placeholder: "Phone",
    value: "",
    isValid: true,
    name: "contactPersonPhone",
  },
  {
    className: "form-input margin-top",
    type: "email",
    placeholder: "User email",
    value: "",
    isValid: true,
    name: "email",
  },
  {
    className: "form-input",
    type: "password",
    placeholder: "Password",
    value: "",
    isValid: true,
    name: "password",
  },
  {
    className: "form-input",
    type: "password",
    placeholder: "Password",
    value: "",
    isValid: true,
    name: "password_confirm",
  },
];
